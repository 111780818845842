import mixpanel from 'mixpanel-browser';
import Parse from 'parse'
import { sendCollectPostNotification, sendSubChannelNotification } from '../utils/NotificationSendingHelpers';
import QuestManager from '../Quests/QuestManager';

export default class SeamChannelRepository {
  static emptyChannel(channelData: {
    creator: Parse.Object, // pointer to the Account of the creator
    name: string,
    emoji: string,
    headerImage: string,
  }) {
    const Channel = Parse.Object.extend("Channel");
    const channel = new Channel();
    const subscriberRelation = channel.relation("subscribers");
    subscriberRelation.add(channelData.creator); // all authors automatically subscribe to their own channel
    channel.set({
      creator: channelData.creator,
      name: channelData.name,
      emoji: channelData.emoji,
      headerImage: channelData.headerImage,
      subscribers: subscriberRelation,
      numSubscribers: 1,
      numPosts: 0,
      isDeleted: false,
    })
    return channel
  }

  static createChannel(channelData: {
    creator: Parse.Object, // pointer to the Account of the creator
    name: string,
    emoji: string,
    headerImage: string,
  }, onSuccess: (newChannel: Parse.Object) => void, onError: (message: any) => void) {
    const channel = SeamChannelRepository.emptyChannel(channelData)
    channel.save().then(async (newChannel: Parse.Object) => {
      mixpanel.track("created channel")
      const followedChannelsRelation = channelData.creator.relation("followedChannels");
      followedChannelsRelation.add(newChannel);
      channelData.creator.save().then(() => {
        window.emitter.emit("SEAM_EVENT_CREATED_COLLECTION", { collection: newChannel })
        onSuccess(newChannel)
      })
    }, (error: any) => {
      onError(error)
    });
  }

  static deleteChannel(channel: Parse.Object, onSuccess: () => void, onError: (message: any) => void) {
    // should deleting a channel also delete all the posts inside of it? No, for now.
    channel.save({ isDeleted: true }).then(() => {
      mixpanel.track("deleted channel")
      onSuccess()
    }, (error: any) => {
      onError(error)
    });
  }

  static async subscribeToChannel(channel: Parse.Object, account: Parse.Object, onSuccess: (updatedAccount: Parse.Object) => void, onError: (message: any) => void) {
    const accountSubscriptions = account.get("followedChannels");
    accountSubscriptions.add(channel);
    const newAccount = await account.save()

    const subscriberRelation = channel.relation("subscribers");
    subscriberRelation.add(account);
    channel.increment("numSubscribers")
    channel.save().then(() => {
      mixpanel.track("subscribed to channel")
      const followedChannelsRelation = account.relation("followedChannels");
      followedChannelsRelation.add(channel);
      newAccount.save().then((finalAccount) => {
        sendSubChannelNotification(account, channel.get("creator").get("userId"), channel)
        onSuccess(finalAccount)
      })
    }, (error: any) => {
      onError(error)
    });
  }

  static async unsubscribeToChannel(channel: Parse.Object, account: Parse.Object, onSuccess: (updatedAccount: Parse.Object) => void, onError: (message: any) => void) {
    const accountSubscriptions = account.get("followedChannels");
    accountSubscriptions.remove(channel);
    const newAccount = await account.save()

    const subscriberRelation = channel.relation("subscribers");
    subscriberRelation.remove(account);
    channel.decrement("numSubscribers")
    channel.save().then(() => {
      mixpanel.track("unsubscribed to channel")
      onSuccess(newAccount)
    }, (error: any) => {
      onError(error)
    });
  }

  static collectPostToChannel(channel: Parse.Object, post: Parse.Object, collector: Parse.Object, setRecentlyUpdatedChannel: (channel: Parse.Object) => void, onSuccess: (postInChannel: Parse.Object) => void, onError: (message: any) => void) {
    const PostInChannel = Parse.Object.extend("PostInChannel");
    const postInChannel = new PostInChannel();
    postInChannel.save({
      channel: channel,
      post: post,
      collector: collector // Account Object
    }).then((savedPostInChannel: Parse.Object) => {
      mixpanel.track("collected post to channel")
      channel.increment("numPosts")
      post.increment("channelCount")
      channel.save()
      const postChannels = post.relation("channels");
      postChannels.add(channel);
      post.save().then((savedPost) => {
        savedPostInChannel.set("post", savedPost)
        sendCollectPostNotification(collector, post.get("author").get("userId"), channel)
        onSuccess(savedPostInChannel)
        setRecentlyUpdatedChannel(channel);
      })
    }, (error: any) => {
      onError(error)
    });
  }

  static removePostFromChannel(post: Parse.Object, postInChannel: Parse.Object, onSuccess: () => void, channel?: Parse.Object) {
    const deletion = async (c: Parse.Object) => {
      post.decrement("channelCount")
      const postChannels = post.relation("channels");
      postChannels.remove(c);
      post.save()
      onSuccess()
    }
    if (postInChannel == undefined) {
      // when we are on the postdetailpage, we might not know which exact postinchannel we are deleting, so we need to find it from the channel
      const query = new Parse.Query("PostInChannel");
      query.equalTo("channel", channel)
      query.equalTo("post", post)
      query.first().then((postInChannel) => {
        postInChannel?.destroy().then((deleted) => {
          deletion(channel!)
        })
      })
    } else {
      postInChannel.destroy().then((deleted) => {
        deletion(deleted.get("channel"))
      })
    }
  }
}