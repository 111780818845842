import React, {
  useReducer,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Stack, Skeleton, Divider } from "@mui/material";
import { useSeamUser } from "../utils/SeamUserContext";
import { useMobile } from '../utils/MobileContext';
import FeedItem from '../Discover/FeedItem';
import { useScrolling } from '../Navigation/ScrollingContext';

const POSTS_PER_PAGE = 10;

const initialState = {
  loadedPosts: [],
  hasMore: true,
  isLoading: false,
  refresh: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'REFRESH':
      return { ...state, loadedPosts: [], hasMore: true, refresh: true };
    case 'POSTS_LOADED':
      const filteredPosts = action.payload.filter(postInChannel => {
        const post = postInChannel.get('post');
        return post?.get('isDeleted') == false;
      });
      return {
        ...state,
        loadedPosts: [...state.loadedPosts, ...filteredPosts],
        isLoading: false,
        hasMore: action.payload.length === POSTS_PER_PAGE,
        refresh: false,
      };
    case 'SET_LOADED_POSTS':
      return { ...state, loadedPosts: action.payload };
    case 'ADD_LOADED_POST':
      return { ...state, loadedPosts: [action.payload, ...state.loadedPosts] };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'DELETE_POST':
      return {
        ...state,
        loadedPosts: state.loadedPosts.filter((prevItem) => {
          const postId = action.payload;
          if (action.itemType === 'Post') {
            const itemId = prevItem.id || prevItem.get('objectId');
            return itemId !== postId;
          } else if (action.itemType === 'PostInChannel') {
            const currentPost = prevItem.get('post');
            const currentPostId = currentPost?.id || currentPost?.get('objectId');
            return currentPostId !== postId;
          }
          return true;
        }),
      };
    case 'ADD_POST':
      return {
        ...state,
        loadedPosts: [action.payload, ...state.loadedPosts],
      };
    default:
      throw new Error('Unknown action type');
  }
}

const Feed = forwardRef(
  (
    {
      fetchPosts,
      Header,
      onPostsLoaded,
      dependencies = [],
      itemType = 'Post',
      isOnChannelPage,
      darkMode,
      ...props
    },
    ref
  ) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const virtuosoRef = useRef();
    const { isScrolling, setIsScrolling } = useScrolling();
    const { refreshAccount } = useSeamUser();

    let color = darkMode ? 'white' : 'black';
    let blueColor = darkMode ? 'text-seam-dark-blue' : 'text-seam-blue';

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        if (virtuosoRef.current) {
          virtuosoRef.current.scrollToIndex({ index: 0, behavior: 'smooth' });
        }
      },
      addPost: (post) => {
        dispatch({ type: 'ADD_POST', payload: post });
      },
      reload: () => {
        dispatch({ type: 'REFRESH' });
      },
    }));

    useEffect(() => {
      if (!state.isLoading && (state.loadedPosts.length === 0 || state.refresh)) {
        loadMore();
      }
    }, [state.refresh]);

    useEffect(() => {
      if (dependencies.length > 0) {
        dispatch({ type: 'REFRESH' });
      }
    }, dependencies); // needed to refresh home feed if you unfollow/follow a channel / block someone etc.

    useEffect(() => {
      if (onPostsLoaded) {
        onPostsLoaded(state.loadedPosts);
      }
    }, [state.loadedPosts, onPostsLoaded]);

    useEffect(() => {
      const handleDelete = (postOrId) => {
        let postId;
        if (typeof postOrId === 'string') {
          postId = postOrId;
        } else if (postOrId && typeof postOrId === 'object') {
          postId = postOrId.id || postOrId.get('objectId');
        } else {
          return;
        }
        if (!postId) return;
        dispatch({ type: 'DELETE_POST', payload: postId, itemType });
      };

      window.emitter.on('SEAM_EVENT_DELETE_POST', handleDelete);
      return () => {
        window.emitter.off('SEAM_EVENT_DELETE_POST', handleDelete);
      };
    }, []); 

    // Handle post addition
    useEffect(() => {
      const handleAdd = (newItem) => {
        if (!newItem) return;
        dispatch({ type: 'ADD_POST', payload: newItem });
      };

      window.emitter.on('SEAM_EVENT_POST_SUCCESSFUL', handleAdd);
      return () => {
        window.emitter.off('SEAM_EVENT_POST_SUCCESSFUL', handleAdd);
      };
    }, []); 

    async function loadMore() {
      if (state.isLoading || !state.hasMore) return;
      dispatch({ type: 'SET_IS_LOADING', payload: true });
      const posts = await fetchPosts(state.loadedPosts.length, POSTS_PER_PAGE);
      
      dispatch({ type: 'POSTS_LOADED', payload: posts });
    }

    function handleRefresh() {
      return new Promise((resolve) => {
        if (refreshAccount) refreshAccount();
        dispatch({ type: 'REFRESH' });
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    }

    const FooterComponent = ({ context }) => {
      const { isLoading } = context.state;
      const { isMobileApp } = useMobile();
      return isLoading ? (
        <div
          className={`w-auto px-6 h-full my-6 ${
            !isMobileApp && 'max-w-[720px] flex flex-col justify-center mx-auto'
          }`}
        >
          <Stack spacing={1}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={80} />
          </Stack>
        </div>
      ) : (
        <div className="bg-transparent h-[200px]" />
      );
    };

    return (
      <PullToRefresh
        onRefresh={handleRefresh}
        pullDownThreshold={75}
        pullingContent={null}
        maxPullDownDistance={175}
        resistance={2}
      >
        <Virtuoso
          ref={virtuosoRef}
          className="hide-scrollbar w-full ion-content-scroll-host"
          data={state.loadedPosts}
          endReached={loadMore}
          overscan={6}
          isScrolling={(scrolling) => setIsScrolling(scrolling)} 
          itemContent={(index, item) => {
            const post = itemType == 'Post' ? item : item.get('post');
            const postInCollection = itemType == 'PostInChannel' ? item : null;
            const collection = item?.get("channel");
            const textColor = isOnChannelPage ? `bg-${collection?.get("fontColor")}` : "bg-gray-500";
            return (<div key={post.id} className="w-full max-w-[720px] h-auto m-auto px-4 rounded">
              <FeedItem post={post} collection={collection} postInCollection={postInCollection} isOnChannelPage={isOnChannelPage} darkMode={darkMode}/>
              <div className="h-[16px]"></div>
              <Divider className={`w-full h-auto bg-${color} opacity-[40%]`} />
              <div className="h-[16px]"></div>
            </div>)
          }}
          increaseViewportBy={{ top: 800, bottom: 300 }}
          components={{
            Header,
            Footer: FooterComponent,
          }}
          context={{ state, dispatch, ...props }}
        />
      </PullToRefresh>
    );
  }
);

export default Feed;