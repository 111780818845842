import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Avatar } from "@mui/material";
import MentionSearch from "../Comment/Mentions/MentionSearch";
import { useSeamUser } from "../utils/SeamUserContext";
import { replaceMentionsInText } from "../components/helpers/formatters";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import SeamComposer from "../Composer/SeamComposer";
import BlockFactory from "../Block-SDK/src/blocks/BlockFactory";
import { X } from "react-feather";
import { useMobile } from "../utils/MobileContext";

const COMMENT_LENGTH_LIMIT = 50000;

const CommentComposer = ({ onSubmit, isActivePage }) => {
  const { account } = useSeamUser();
  const { isMobileApp } = useMobile();
  const [comment, setComment] = React.useState("");
  const [mentionList, setMentionList] = useState([]);
  const [miniappData, setMiniappData] = useState(undefined);
  const commentComposerInputRef = useRef(null);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_REPLY_TO_COMMENT", (comment) => {
      setComment(`@${comment.get("author").get("profileId")} `);
      commentComposerInputRef.current?.focus();
    });
  }, []);

  const addNewComment = () => {
    if (comment.trim() === "" && miniappData == undefined) return; // Ensure we don't submit just whitespace

    if (comment?.length > COMMENT_LENGTH_LIMIT) {
      // Handle error for comment length limit exceeded
    }

    const newComment = replaceMentionsInText(comment.trim());
    const mentioneesFormatted = mentionList.map((mention) => {
      return { type: "user", userIds: [mention.id] };
    });

    onSubmit(newComment, mentioneesFormatted, miniappData);
    setComment(""); // Reset comment after submit
    setMiniappData(undefined);
  };

  const addMention = (mentionId) => {
    setMentionList([...mentionList, { id: mentionId }]);
  };

  const renderMiniappComment = () => {
    if (!miniappData) return null;

    return (
      <div className="relative flex flex-row justify-center min-w-full min-h-full">
        <div className="absolute z-10 top-2 right-2 rounded-full bg-seam-gray p-1" onClick={() => setMiniappData(undefined)}>
          <X size={16} />
        </div>
        {BlockFactory.getFeedComponent(miniappData, () => { })}
      </div>
    )
  }

  const processedProfilePhoto = getProcessedProfilePictureURL(account?.get("profilePhoto"))
  return (
    <div
      className={`sticky bottom-0 bg-[#FCFCFC] p-4 z-10 border-2 border-b-0 border-seam-gray ${!isMobileApp ? "rounded-t-[20px]" : "rounded-[20px]"}`}
      style={isMobileApp ? { paddingBottom: "env(safe-area-inset-bottom)" } : { paddingBottom: "16px" }}
    >
      <div className="flex flex-col items-center max-w-full">
        <div className="w-full h-full">
          {renderMiniappComment()}
        </div>
        <div className="flex flex-row w-full pt-2 items-center h-full justify-center">
          <div className="mr-4">
            <Avatar
              className="rounded-full h-8 w-8"
              src={processedProfilePhoto}
              alt="Profile"
            />
          </div>
          <div className="flex flex-row w-full justify-center items-center">
            <MentionSearch
              textValue={comment}
              mentionList={mentionList}
              onChange={(_, newValue) => setComment(newValue)}
              addMention={addMention}
              placeholder="What's on your mind?"
              inputRef={commentComposerInputRef}
              onSubmit={addNewComment}
            />
            <div className={`w-auto h-full text-white rounded-full bg-[#ea3bf7] ml-2`}>
              {isActivePage && <SeamComposer
                composerTitle={"Reply with miniapp"}
                communityName={"Comment"}
                communityId={"Comment"}
                currentCard={undefined}
                completion={() => { }}
                postingMiniappsOnly={true}
                commentCompletionOverride={(data) => {
                  setMiniappData(data);
                }}
              />}
            </div>
          </div>
        </div>
      </div>
      <Divider className="mt-2" />
      <Button
        onClick={addNewComment}
        fullWidth
        variant="contained"
        color="primary"
        className="my-4 h-[60px] justify-center items-center flex rounded-[8px] bg-seam-blue"
        disabled={comment.trim().length === 0 && miniappData == undefined} // Disable button if comment is only whitespace or empty
      >
        Comment
      </Button>
    </div>
  );
};

export default CommentComposer;
