import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { IonPage, IonContent } from "@ionic/react";
import { ChevronLeft } from "react-feather";
import { useSeamUser } from "../utils/SeamUserContext";
import Parse from "parse";
import SeamUserItem from "../components/SeamUserItem";
import FriendRequestButton from "../InternalBlocks/FriendRequestButton";
import { Virtuoso } from "react-virtuoso";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import Header from "../Navigation/Header";
import DesktopSidebarWrapper from "../components/DesktopSidebarWrapper";
import { useMobile } from "../utils/MobileContext";
import SeamPillButton from "../Block-SDK/src/components/SeamPillButton";
import { Typography } from "@mui/material";

const CONNECTIONS_PER_PAGE = 20;

const FriendsPage = () => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const { profileId } = useParams();
  const { contextObject } = useSeamNavigator();
  const [creatorAccount, setCreatorAccount] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const isOwnProfile = account ? account.get("profileId") === profileId : false;

  useEffect(() => {
    const fetchCreatorAccount = async () => {
      if (isOwnProfile) {
        setCreatorAccount(account);
        return;
      }
      if (contextObject?.get("profileId") === profileId) {
        setCreatorAccount(contextObject);
        return
      }
      // Fetch the creator's account based on the profileId
      try {
        const accountQuery = new Parse.Query("Account");
        accountQuery.equalTo("profileId", profileId);
        const result = await accountQuery.first();
        if (result) {
          setCreatorAccount(result);
        } else {
          console.error("Creator account not found");
          // Handle the case where the creator account is not found
        }
      } catch (error) {
        console.error("Error fetching creator account:", error);
        // Handle fetching error
      }
    };
    fetchCreatorAccount();
  }, [account, profileId, isOwnProfile]);

  useEffect(() => {
    // Set the page title based on whether it's the user's own profile or someone else's
    if (creatorAccount) {
      const title = isOwnProfile
        ? "Your Friends"
        : `${creatorAccount.get("username")}'s Friends`;
      setPageTitle(title);
    }
  }, [creatorAccount, isOwnProfile]);

  useEffect(() => {
    if (creatorAccount) {
      loadMoreConnections(0);
    }
  }, [profileId, creatorAccount]);

  const loadMoreConnections = async (startIndex) => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const connectionsRelation = creatorAccount.relation("Connections");
      const query = connectionsRelation.query();
      query.skip(startIndex);
      query.limit(CONNECTIONS_PER_PAGE);
      query.descending("updatedAt")
      const result = await query.find();
      if (result.length < CONNECTIONS_PER_PAGE) {
        setHasMore(false);
      }
      setConnections((prev) => [...prev, ...result]);
    } catch (error) {
      console.error("Failed to fetch connections:", error);
    } finally {
      setLoading(false);
    }
  };

  const leftIcon = () => {
    return (
      <>
        <div className={`${isMobileApp ? 'ml-4' : 'ml-[48px]'}`}>
          <SeamPillButton
            icon={<ChevronLeft className={`text-seam-black h-4 w-4`} />}
            label={null}
            textColor={"black"}
            onClick={() => {history.go(-1)}}
            isOnChannelPage={false}
          />
        </div>
      </>
    )
  }

  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  return (
    <IonPage>
      <Header
        isActivePage={true}
        leftIcon={isMobileApp && leftIcon()}
        rightIcon={null}
        leftAction={leftAction}
        centerIcon={isMobileApp ? <Typography variant="h3" className="self-center text-center my-auto h-full">{pageTitle}</Typography> : null}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <DesktopSidebarWrapper showDesktopHeader={true} leftIcon={leftIcon()} centerIcon={<h3>{pageTitle}</h3>} rightIcon={null}>
          <div className="flex flex-col w-full h-full">
            <Virtuoso
              style={{ height: window.innerHeight - 56 }}
              className={`ion-content-scroll-host`}
              data={connections}
              endReached={() => loadMoreConnections(connections.length)}
              overscan={3}
              itemContent={(index, friend) => (
                <div className="p-4 w-full max-w-[720px] mx-auto">
                  <SeamUserItem
                    avatar={friend.get("profilePhoto")}
                    title={<h3>@{friend.get("profileId")}</h3>}
                    link={`/user/${friend.get("profileId")}`}
                  />
                </div>
              )}
            />
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default FriendsPage;
