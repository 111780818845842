import React, { createRef, memo, useEffect, useRef, useState } from "react";
import DiscoveryFeed from "../Discover/DiscoveryFeed";
import { useSeamUser } from "../utils/SeamUserContext";
import {
  IonPage,
  IonContent,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import Header from "../Navigation/Header";
import { Search } from "react-feather";
import { NotificationsNone } from "@mui/icons-material";
import SeamComposer from "../Composer/SeamComposer";
import useSearchReducer from "../utils/useSearchReducer";
import DesktopSidebarWrapper from "../components/DesktopSidebarWrapper";
import { Button } from "@mui/material";
import AdminFeed from "../Groups/AdminFeed";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { useMobile } from "../utils/MobileContext";

function HomePage() {
  const { account, isAdmin } = useSeamUser();
  const seamNavigator = useSeamNavigator();
  const { isMobile, isMobileApp } = useMobile();
  const [state, dispatch] = useSearchReducer();
  const [isActivePage, setIsActivePage] = useState(false);
  const [showAdminFeed, setShowAdminFeed] = useState(false);
  const virtuosoRef = useRef(null);

  const handleToggleSearchBar = () => {
    dispatch({ type: "TOGGLE_SEARCH_BAR" });
  };

  useEffect(() => {
    window.addEventListener('toggleSearchBar', handleToggleSearchBar);

    return () => {
      window.removeEventListener('toggleSearchBar', handleToggleSearchBar);
    };
  }, []);

  useIonViewWillEnter(() => {
    setIsActivePage(true);
  });

  useIonViewWillLeave(() => {
    setIsActivePage(false);
  });

  const handleSearch = (searchString) => {
    dispatch({ type: "UPDATE_SEARCH_STRING", payload: searchString });
  };

  const scrollToTop = () => {
    virtuosoRef.current?.scrollToTop();
  };

  const handleNotifClick = () => {
    seamNavigator.navigateTo("/notifications");
  }

  return (
    <IonPage>
      <Header
        isActivePage={isActivePage}
        leftIcon={isMobile && <div className="bg-red"><Search color="black" className="py-2 px-6 cursor-pointer" /></div>}
        rightIcon={isMobile && <NotificationsNone color="black" className="py-2 px-6 cursor-pointer" />}
        leftAction={isMobile && handleToggleSearchBar}
        leftActionOnMobileApp={true}
        rightAction={isMobile && handleNotifClick}
        showSearchBar={state.showSearchBar}
        searchState={state}
        dispatch={dispatch}
        onSearchSubmit={handleSearch}
        scrollToTop={scrollToTop}
        centerIcon={undefined}
      />
      <IonContent fullscreen={true} scrollY={false} scrollX={false}>
        <DesktopSidebarWrapper 
          showDesktopHeader={!isMobile}
          navSection={"feed"}
        >
          <div className="flex flex-col w-full h-full">
            {isAdmin && (
              <div className="w-full flex justify-center my-4">
                <Button onClick={() => setShowAdminFeed(!showAdminFeed)}>
                  {showAdminFeed ? 'Show Discovery Feed' : 'Show Admin Feed'}
                </Button>
              </div>
            )}
            <div className="flex-grow overflow-hidden">
              {showAdminFeed ? (
                <AdminFeed />
              ) : (
                <DiscoveryFeed isActivePage={isActivePage} ref={virtuosoRef} />
              )}
            </div>
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
}

export default memo(HomePage);