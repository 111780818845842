import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import SeamPostRepository from "./Post/SeamPostRepository";
import logo from "./assets/logo.svg";
import { useParams } from 'react-router';
import SeamUserFeedItem from './InternalBlocks/WallBlock/SeamUserFeedItem';
import UserProfilePage from './Profile/UserProfilePage';
import ChannelPage from './Channels/ChannelPage';

interface ChannelMetaSEOImageProps {
  channelId?: string;
}

// a standalone route so that it can be screenshot by puppeteer on the backend to provide an image for SEO and metadata
const ChannelMetaSEOImage = (props: ChannelMetaSEOImageProps) => {
  const [channel, setChannel] = useState<Parse.Object | undefined>(undefined);
  const { channelId } = useParams<{ channelId: string }>();

  useEffect(() => {
    const fetchChannel = async () => {
      if (!channelId) {
        return;
      }
      const channelQuery = new Parse.Query("Channel");
      channelQuery.equalTo("objectId", channelId);
      const channel = await channelQuery.first();
      setChannel(channel);
    };

    fetchChannel();
  }, [channelId]);

  return (
    <>
    {channel && <div data-testid="seam-collection-item" className="w-full h-full max-w-full overflow-clip flex flex-col items-start p-2 rounded-[20px] border border-gray-200 bg-[#FCFCFC] shadow-none">
      <ChannelPage channelId={channelId} takingScreenshot={true} />
    </div>}
    </>
  );
};

export default ChannelMetaSEOImage;