import React from 'react';
import ImageUploadComponent from "../components/ImageUploadComponent";
import { Avatar, Typography } from '@mui/material';
import { useMobile } from '../utils/MobileContext';
import GiphyButtonComponent from '../components/GiphyButtonComponent';
import { AddPhotoAlternate } from '@mui/icons-material';

function ProfilePhotoPage({ profilePic, setProfilePic }) {
  const { isMobile, isMobileApp } = useMobile();

  const handleAvatarClick = (url) => {
    setProfilePic(url); // Update the selected profile picture
  };

  return (
    <div className="h-full w-auto flex flex-col items-center justify-between overflow-hidden">
      <div className="py-4 flex mt-[64px] flex-col items-center justify-start h-full truncate w-auto space-y-6">
        <Typography variant="h4" className="text-seam-black/50 text-center">Add a Photo or find a GIF</Typography>
        <div className="flex flex-row items-center justify-center w-auto h-auto space-x-6">
          <ImageUploadComponent fileTypes="image/*" onUpdate={(url) => setProfilePic(url)} />
          <GiphyButtonComponent onUpdate={(url) => setProfilePic(url)} />
        </div>
        {profilePic ?
          <Avatar src={profilePic} alt="Profile Picture" className="w-[148px] h-[148px] self-center my-8" />
          :
          <div className="flex items-center justify-center bg-[#EFEFEF] w-[148px] h-[148px] rounded-full self-center my-8">
            <AddPhotoAlternate className="text-seam-black/50 w-6 h-6" />
          </div>
        }
      </div>
    </div>
  );
}

export default ProfilePhotoPage;