import React, { useState, useEffect } from "react";
import { useMobile } from "../utils/MobileContext";
import { Avatar, Typography } from "@mui/material";
import Parse from "parse";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import { useSeamUser } from "../utils/SeamUserContext";
import { Capacitor } from "@capacitor/core";
import { IonTabButton, IonTabBar, IonTabs, useIonRouter } from "@ionic/react";
import { motion } from "framer-motion";
import SeamComposer from "../Composer/SeamComposer";
import "./MobileBottomNav.css";
import { Groups } from "@mui/icons-material";
import { useScrolling } from "./ScrollingContext";
import logo from '../assets/logo.svg';

const tabVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export default function MobileBottomNav({ children }) {
  const [keyboardShowing, setKeyboardShowing] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [currentTab, setCurrentTab] = useState("profile");
  const { isMobile, isMobileApp } = useMobile();
  const { isScrolling } = useScrolling();
  const router = useIonRouter();
  const { account, hasUnreadNotifications, recentlyUpdatedChannel, login } = useSeamUser();
  const isAuthenticated = Parse.User.current() != undefined;
  const currentAccountProcessedProfilePhoto = getProcessedProfilePictureURL(
    account?.get("profilePhoto")
  );
  const hideBottomNav =
    router.routeInfo.pathname.includes("/post/") ||
    router.routeInfo.pathname.includes("/comment/");

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") {
      return;
    }

    const handleKeyboardDidShow = (ev) => {
      setKeyboardShowing(true);
    };

    const handleKeyboardDidHide = () => {
      setKeyboardShowing(false);
    };

    window.addEventListener("ionKeyboardDidShow", handleKeyboardDidShow);
    window.addEventListener("ionKeyboardDidHide", handleKeyboardDidHide);

    return () => {
      window.removeEventListener("ionKeyboardDidShow", handleKeyboardDidShow);
      window.removeEventListener("ionKeyboardDidHide", handleKeyboardDidHide);
    };
  }, []);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_TOGGLE_MODE", (mode) => {
      setIsInEditMode(mode === "edit");
    });
  }, []);

  const showSignInButton =
    !isAuthenticated &&
    !isMobileApp &&
    !account

  const showMobileNav = 
    (isMobileApp || isMobile) && 
    isAuthenticated && 
    account && 
    account.get("isOnboarded") === true


  const mobileTabsVisible = !isInEditMode && !keyboardShowing && !hideBottomNav;
  const tabBarOpacity = isScrolling ? 0 : 1; 

  if (showSignInButton) {
    return (
    <>
    <IonTabs className="w-auto" style={{ marginTop: "env(safe-area-inset-top)", }}>
      {children}
      <IonTabBar
        slot="bottom"
        style={{
          visibility: mobileTabsVisible ? "visible" : "hidden",
          paddingBlock: '4px',
          paddingInline: '2px',
          borderRadius: '64px',
          opacity: tabBarOpacity,
          transition: 'opacity 0.3s ease',
        }}
        className={`flex flex-row items-center justify-center ${!isMobile && 'ml-[25rem]'} h-[70px] w-[90px]`}
      >
        <IonTabButton className={`w-[90px] h-[70px]`}>
          <>
          <div onClick={(e) => {
            e.preventDefault(); 
            login();
          }} 
          className="relative flex flex-row items-center rounded-[64px] bg-seam-pink h-[70px] w-[90px] justify-center"
          >
            <Typography variant="h4" className="text-white">Sign in</Typography>
          </div>
          </>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
    </>
    )
  }

  else if (showMobileNav) {
    return (
    <IonTabs
      onIonTabsDidChange={(event) => {
        setCurrentTab(event.detail.tab);
      }}
      style={{ marginTop: "env(safe-area-inset-top)", }}
    >
      {children}
      <IonTabBar
        slot="bottom"
        style={{
          visibility: mobileTabsVisible ? "visible" : "hidden",
          paddingBlock: '4px',
          paddingInline: '2px',
          borderRadius: '68px',
          opacity: tabBarOpacity,
          transition: 'opacity 0.3s ease',
        }}
        className="flex flex-row items-center justify-center h-[70px] w-[222px]"
      >
        <IonTabButton className="w-[70px] h-[70px]" href={isMobileApp ? "/homeTab" : "/home"} tab="home">
          <div className="relative flex justify-center items-center w-[70px] h-[70px] bg-[#232323] rounded-full">
            <motion.div
              initial={currentTab === "home" ? "visible" : "hidden"}
              animate={currentTab === "home" ? "visible" : "hidden"}
              variants={tabVariants}
              transition={{ duration: 0.3 }}
              className="bg-white absolute h-[70px] w-[70px] rounded-full"
            />
              <Groups
                className={`z-10 transition-colors duration-300 ${
                  currentTab === "home" ? "text-[#323232]" : "text-[#d4d4d4]"
                }`}
              />
          </div>
        </IonTabButton>
        <IonTabButton className="w-[70px] h-[70px]">
          <div className="relative flex flex-row items-center rounded-full bg-seam-pink h-[70px] w-[70px] justify-center">
            {<SeamComposer 
              composerTitle={"Share what's on your mind"}
              communityName={"Seam"}
              communityId={account?.get("profileId")}
              currentCard={undefined}
              channel={recentlyUpdatedChannel}
              completion={() => {}}
            />}
          </div>
        </IonTabButton>
        <IonTabButton className="w-[70px] h-[70px]" tab="profile" href="/profileTab">
          <div className="relative flex justify-center bg-[#232323] items-center w-[70px] h-[70px] rounded-full">
            <motion.div
              initial={currentTab === "profile" ? "visible" : "hidden"}
              animate={currentTab === "profile" ? "visible" : "hidden"}
              variants={tabVariants}
              transition={{ duration: 0.3 }}
              className="absolute bg-white rounded-full h-[70px] w-[70px]"
            />
            <Avatar
              src={currentAccountProcessedProfilePhoto}
              style={{
                width: 22,
                height: 22,
                border: currentTab === "profile" ? "2px solid #323232" : "2px solid #FEFEFE",
                zIndex: 1
              }}
            />
          </div>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
    )
  }

  else return children;
}